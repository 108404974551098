import React from 'react';
import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';
import {
  DisplayText,
  Header,
  LayoutUI,
  Stack,
  TextStyle,
  WithFloatingArrow
} from '../../../ui-kit';
import * as styles from './Coaching.module.scss';
import ROUTES from '../../../utils/routes';

const Coaching = () => (
  <Layout>
    <Navbar />
    <MobileNavbar />
    <div className={styles.Container}>
      <LayoutUI narrow>
        <Stack
          alignment="center"
          distribution="center"
          spacing="loose"
          vertical
        >
          <DisplayText>Coming Soon!</DisplayText>
          <Header size="small">
            We are hard at work perfecting our coaching program. Please check
            back here for more information in the future.
          </Header>
          <WithFloatingArrow>
            {floatingArrow => (
              <a href={ROUTES.getInvolved()}>
                <TextStyle color="blue">
                  More ways to get involved
                  {floatingArrow}
                </TextStyle>
              </a>
            )}
          </WithFloatingArrow>
        </Stack>
      </LayoutUI>
    </div>
  </Layout>
);

export default Coaching;
